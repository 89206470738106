import React from "react"
import { Link } from "gatsby"
import UseHttpRequest from "../../hooks/HttpRequestSponsors"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

const SponsorsList = () => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
    }
    const allData = UseHttpRequest()
    if (allData.length <= 0) {
        return (
            <div className="row justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    const data = allData.Sponsors;
    console.log(data);
    console.log(window.location.href.indexOf("sponsors"));
    return (
        <>
            <section className="team-section pt-70 pb-70">
                <div className="container">
                    <div className="team-title-area">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="section-title text-left">
                                    <span>
                                        <i className="flaticon-support" />
                                        Our Sponsors
                                    </span>
                                    <h2>Event and Ads Sponsors</h2>
                                    <p>
                                        KYTS appreciates all current and past members, volunteers
                                        and committee members hard work and dedication since 2003.
                                    </p>
                                </div>
                            </div>
                            {window.location.href.indexOf("sponsors") === -1 &&
                                <div className="col-lg-5">
                                    <div className="team-btn text-right">
                                        <Link to="/sponsors" className="optional-btn">
                                            Our Sponsors
                                            <i className="flaticon-right" />
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <Slider {...settings}>
                                {data.map((item, index) => (
                                    <div key={index}>
                                        <div className="single-team-member">
                                            <div className="team-image">
                                                <img src={item.ImageURL} alt="" />
                                            </div>

                                            <div className="member-content">
                                                <a href={item.WebsiteURL} target="_blank" rel="noreferrer">
                                                    <h3>{item.SponsorName}</h3>
                                                    <span>{item.Description}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SponsorsList

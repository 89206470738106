import { useState, useEffect } from "react"
const UseHttpRequest = props => {
    const apiUrl = props => {
        const ApiUrl = `https://demo.kentuckytamilsangam.org/api/Sponsors/GetAllSponsors`
        // const ApiUrl = `https://localhost:44334/api/Sponsors/GetAllSponsors`
        return ApiUrl
    }
    const [data, setData] = useState([])
    const url = apiUrl(props)

    useEffect(() => {
        let mounted = true
        const runAsync = async () => {
            try {
                let response = await fetch(url)
                let data = await response.json()
                if (mounted) {
                    setData(data)
                }
            } catch (error) {
                throw error
            }
        }
        runAsync()
        return () => {
            mounted = false
        }
    }, [url])

    return data
}

export default UseHttpRequest

import React from "react"
import { Link } from "gatsby"
import SponsorsList from "../about/sponsors"

const SponsorsPage = () => {
    return (
        <>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Sponsors</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>Sponsors</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SponsorsList />
        </>
    )
}

export default SponsorsPage
